import React, { createContext, useState } from 'react';
import { useEffect } from 'react';
export const StoreContext = createContext(null)

const StoreProvider = ({ children }) => {
    const windowGlobal = typeof window !== 'undefined' && window
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [newsLimit, setNewsLimit] = useState(3)
    const [language, setLanguage] = useState("EN")
    useEffect(() => {
        setLanguage(windowGlobal.localStorage?.getItem("language") || "EN")
    }, [])

    return (
        <StoreContext.Provider
            value={{
                isMenuOpen,
                setIsMenuOpen,
                newsLimit,
                setNewsLimit,
                language,
                setLanguage
            }}
        >
            {children}
        </StoreContext.Provider>
    );
}

export default StoreProvider;